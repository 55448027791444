/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowseRequest.json#"},"AuthEnterpriseID":{"type":"string","maxLength":30},"AuthNum":{"type":"string","maxLength":30},"AuthSubTypeID":{"type":"string","maxLength":100},"AuthTypeID":{"type":"string","maxLength":100},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"FromDate":{"format":"date-time","nullable":true,"type":"string"},"HealthPlanTypeID":{"type":"string","maxLength":32},"IPID":{"type":"integer"},"IsCCS":{"type":"boolean"},"IsMineOnly":{"type":"boolean"},"IsProviderPortal":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"NetworkStatus":{"type":"string","maxLength":30},"ReferredFacilityID":{"type":"integer"},"ReferredFromID":{"type":"integer"},"ReferredToID":{"type":"integer"},"ToDate":{"format":"date-time","nullable":true,"type":"string"},"VisitStatusID":{"items":{"type":"string"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowseRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowseRequest.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
