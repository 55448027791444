/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$ParentID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$ServiceID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$Status.json';
import schema_3 from './com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$VisitTypeID.json';
import schema_4 from './com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$XeAuth.json';
import schema_5 from './com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$XePatientIssue.json';
import schema_6 from './com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$XeVisitCaseManagementProgram.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$IVID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DerivedCaseIVID":{"type":"string"},"DerivedCaseVisitID":{"type":"string"},"DerivedProgram":{"type":"string"},"DerivedProgramID":{"type":"string"},"DerivedProgramIVID":{"type":"string"},"DerivedProgramVisitID":{"type":"string"},"IVID":{"type":"integer","primaryKey":"generated"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ParentID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$ParentID.json#"},"ParentIDTableIdentifier":{"type":"string"},"RestrictValue":{"maximum":99999,"type":"integer"},"ServiceID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$ServiceID.json#"}]},"StartDate":{"format":"local-date-time","nullable":true,"type":"string"},"StartDateOff":{"type":"string"},"Status":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$Status.json#"}]},"StatusInline":{"type":"string"},"TableIdentifier":{"type":"string"},"VisitID":{"type":"string","maxLength":60},"VisitTypeID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$VisitTypeID.json#"},"XeAuth":{"$ref":"com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$XeAuth.json#"},"XePatientIssue":{"$ref":"com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$XePatientIssue.json#"},"XeVisitCaseManagementProgram":{"$ref":"com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$XeVisitCaseManagementProgram.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$IVID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$IVID.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3, schema_4, schema_5, schema_6]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
