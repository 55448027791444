/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogResponse$XeOutreachItems.json#"},"CallLogID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"OutreachItemID":{"type":"integer","primaryKey":"generated"},"OutreachType":{"type":"string","maxLength":64},"VisitAssessmentID":{"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogResponse$XeOutreachItems.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.calllog.AddCallLogResponse$XeOutreachItems.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
