/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.calllog.CallLogContact$XePhone.json#"},"AddressID":{"type":"string","maxLength":32},"ContactInfoTypeID":{"type":"string"},"EnterpriseID":{"type":"string"},"IDName":{"type":"string","maxLength":100},"Label":{"type":"string","maxLength":100},"Phone":{"type":"string"},"PhoneAttrType":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.calllog.CallLogContact$XePhone.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.calllog.CallLogContact$XePhone.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
