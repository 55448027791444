/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.auth.AuthResponse$XeOrderBaseDispensable.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.auth.AuthResponse$XeOrderBase.json#"},"AllowedUnits":{"maximum":99999,"type":"integer"},"AuthNum":{"type":"string","maxLength":60},"AuthorizedUnits":{"maximum":99999,"type":"integer"},"Code":{"type":"string","maxLength":32},"CodeTypeID":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"OrderID":{"type":"integer","primaryKey":"generated"},"RequestedUnits":{"maximum":99999,"type":"integer"},"ServiceID":{"type":"integer"},"ServiceSubTypeID":{"type":"string","maxLength":32},"ServiceTypeID":{"type":"string","maxLength":32},"WellKnownID":{"type":"string","maxLength":50},"XeOrderBaseDispensable":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.AuthResponse$XeOrderBaseDispensable.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.auth.AuthResponse$XeOrderBase.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthResponse$XeOrderBase.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
