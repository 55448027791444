/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleRequest.json#"},"Active":{"type":"boolean"},"ActivityAsset":{"items":{"type":"string"},"type":"array"},"AsFile":{"type":"string"},"AuthNum":{"type":"string","maxLength":30},"AuthSubTypeID":{"type":"string","maxLength":100},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Description":{"type":"string"},"EndDate":{"format":"date-time","nullable":true,"type":"string"},"ErrorDescription":{"type":"string","maxLength":1024},"ExpirationDate":{"format":"date-time","nullable":true,"type":"string"},"HealthPlanTypeID":{"type":"string"},"IVID":{"type":"integer","primaryKey":"supplied"},"IsInError":{"type":"boolean"},"IsInPatient":{"type":"boolean"},"IsProviderPortal":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"OutboundQueuedTime":{"format":"date-time","nullable":true,"type":"string"},"ReceivedDate":{"format":"date-time","nullable":true,"type":"string"},"ReceivedDateOperator":{"type":"string"},"RequestedPOSID":{"type":"integer"},"StartDate":{"format":"date-time","nullable":true,"type":"string"},"State":{"type":"string"},"Status":{"type":"string"},"WorkID":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleRequest.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
