/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchEntry$XeClaimBatchEntry.json#"},"Active":{"type":"boolean"},"ClaimBatchEntryID":{"type":"string","primaryKey":"generated","maxLength":120},"ClaimBatchID":{"type":"string","maxLength":120},"ClearingHouseID":{"type":"string","maxLength":200},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"EncFormID":{"type":"integer"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"StatusID":{"type":"string","maxLength":32},"SubBatchID":{"type":"string","maxLength":120},"TransactionType":{"type":"string","maxLength":30}},"$id":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchEntry$XeClaimBatchEntry.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchEntry$XeClaimBatchEntry.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
