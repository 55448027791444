/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$AssessmentID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$SignedBy.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$VisitAssessmentID.json#"},"Active":{"type":"boolean"},"AssessmentID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$AssessmentID.json#"},"AssessmentTypeID":{"type":"string","maxLength":32},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"IPID":{"type":"integer"},"IVID":{"type":"integer"},"InternalCode":{"type":"string","maxLength":50},"IsInError":{"type":"boolean"},"IsInErrorFilter":{"type":"string"},"IsQuickSave":{"type":"string","maxLength":1},"IsQuickSaveFilter":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"RestrictValue":{"maximum":99999,"type":"integer"},"ReviewStatusID":{"type":"string"},"ReviewStatusIDOperator":{"type":"string"},"RightsBase":{"type":"string","maxLength":32},"ScanDocID":{"type":"integer"},"SignedBy":{"$ref":"com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$SignedBy.json#"},"SignedDate":{"format":"date-time","nullable":true,"type":"string"},"Status":{"type":"string","maxLength":32},"VisitAssessmentID":{"type":"integer","primaryKey":"generated"}},"$id":"com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$VisitAssessmentID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$VisitAssessmentID.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
