/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$ClearingHouseID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$StatusID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$XeClaimBatchEntry.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull.json#"},"AcceptedTimestamp":{"format":"date-time","nullable":true,"type":"string"},"Active":{"type":"boolean"},"BatchTimestamp":{"format":"date-time","nullable":true,"type":"string"},"ClaimBatchID":{"type":"string","primaryKey":"generated","maxLength":120},"ClearingHouseID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$ClearingHouseID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"ErrorDescription":{"type":"string","maxLength":1024},"IsInError":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedTStampMax":{"format":"date-time","nullable":true,"type":"string"},"ModifiedTStampMin":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"ProcessedTimestamp":{"format":"date-time","nullable":true,"type":"string"},"StatusID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$StatusID.json#"},"SubmissionTimestamp":{"format":"date-time","nullable":true,"type":"string"},"XeClaimBatchEntry":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$XeClaimBatchEntry.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
