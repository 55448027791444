/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Advanced.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Basic.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$IncludeExclude.json';
import schema_3 from './com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$RelativeDate.json';
import schema_4 from './com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$TopN.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Filters.json#"},"advanced":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Advanced.json#"},"type":"array"},"basic":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Basic.json#"},"type":"array"},"includeExclude":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$IncludeExclude.json#"},"type":"array"},"relativeDate":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$RelativeDate.json#"},"type":"array"},"topN":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$TopN.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Filters.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Filters.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3, schema_4]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
