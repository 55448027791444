/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.delta.ChargeCapture$LocumProvider.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.delta.ChargeCapture$FeatureGates.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.delta.ChargeCapture$Services.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.delta.ChargeCapture.json#"},"DiagnosisCatalogID":{"type":"string"},"EnableTimeCapture":{"type":"boolean"},"LocumProvider":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.ChargeCapture$LocumProvider.json#"},"ModifierCatalogID":{"type":"string"},"ProcedureChargeCatalogID":{"type":"string"},"VisitChargeCatalogID":{"type":"string"},"featureGates":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.ChargeCapture$FeatureGates.json#"},"services":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.ChargeCapture$Services.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.delta.ChargeCapture.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.delta.ChargeCapture.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
