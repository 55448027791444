/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleResponse$AuthTypeID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleResponse$PatientPlanID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleResponse$XePatientVisit.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleResponse.json#"},"AccountID":{"type":"string"},"Active":{"type":"boolean"},"AuthNum":{"type":"string","maxLength":30},"AuthTypeID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleResponse$AuthTypeID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EnterpriseID":{"type":"string"},"IVID":{"type":"integer","primaryKey":"supplied"},"IsPreAuth":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"OutboundQueuedTime":{"format":"date-time","nullable":true,"type":"string"},"PatientPlanID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleResponse$PatientPlanID.json#"},"ReceivedDate":{"format":"date-time","nullable":true,"type":"string"},"ReceivedDateSrv":{"format":"date-time","nullable":true,"type":"string"},"State":{"type":"string"},"XePatientVisit":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleResponse$XePatientVisit.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthSimpleResponse.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
