/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.dashboard.ColumnMetaData.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardDetail.json#"},"Active":{"type":"boolean"},"BookID":{"type":"string","maxLength":64},"ChartItem":{"type":"string","maxLength":50},"ChartName":{"type":"string","maxLength":50},"ColumnMetaData":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.dashboard.ColumnMetaData.json#"},"type":"array"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DashboardID":{"type":"string","primaryKey":"supplied","maxLength":50},"DataIndex":{"maximum":9999,"type":"integer"},"DataQuery":{"type":"string","maxLength":2147483647},"Delimeter":{"type":"string","maxLength":10},"Description":{"type":"string","maxLength":2000},"DocTypeID":{"type":"string","maxLength":40},"FileName":{"type":"string","maxLength":100},"FilterQuery":{"type":"string","maxLength":800},"GlobalAction":{"type":"string","maxLength":50},"GroupID":{"type":"string","maxLength":30},"IsValid":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":200},"PKeyName":{"type":"string","maxLength":30},"PKeyQuery":{"type":"string","maxLength":1500},"ParentID":{"type":"string","maxLength":50},"PivotQuery":{"type":"string","maxLength":200},"SequenceNo":{"maximum":9999,"type":"integer"},"Structured":{"type":"boolean"},"SubDirectory":{"type":"string","maxLength":100},"ViewExists":{"type":"boolean"},"WhereClause":{"type":"string","maxLength":2000},"XeSmartBookInstance":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardDetail.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardDetail.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
