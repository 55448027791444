/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Filters.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Settings.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI.json#"},"AccessToken":{"type":"string"},"EmbedUrl":{"type":"string"},"ReportID":{"type":"string"},"filters":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Filters.json#"},"settings":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Settings.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
