/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails$XeProviderNetworkParticipation.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"ContractStatus":{"type":"string","maxLength":50},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"EffectiveDateTime":{"format":"local-date-time","nullable":true,"type":"string"},"EffectiveDateTimeFilter":{"type":"string"},"EffectiveDateTimeOff":{"type":"string"},"ExpireDateTime":{"format":"local-date-time","nullable":true,"type":"string"},"ExpireDateTimeFilter":{"type":"string"},"ExpireDateTimeOff":{"type":"string"},"FacilityProviderID":{"type":"integer"},"GroupProviderID":{"type":"integer"},"HealthPlanGroupID":{"type":"string","maxLength":32},"HealthPlanGroupIDFilter":{"type":"string"},"HealthPlanPayerID":{"type":"string","maxLength":32},"HealthPlanTypeID":{"type":"string","maxLength":32},"IsAcceptingNewPatients":{"type":"boolean"},"IsPlanNetwork":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"NetworkTier":{"type":"string","maxLength":50},"ProviderAddressID":{"type":"string","maxLength":32},"ProviderID":{"type":"integer"},"ProviderNetworkPartID":{"type":"string","primaryKey":"generated","maxLength":100},"SiteID":{"type":"string","maxLength":60}},"$id":"com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails$XeProviderNetworkParticipation.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails$XeProviderNetworkParticipation.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
