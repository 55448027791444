/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowse$AuthSubTypeID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowse$AuthTypeID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowse$IPID.json';
import schema_3 from './com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowse$PatientPlanID.json';
import schema_4 from './com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowse$UmUrgencyID.json';
import schema_5 from './com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowse$XePatientVisit.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowse.json#"},"Active":{"type":"boolean"},"AuthNum":{"type":"string","maxLength":30},"AuthSubTypeID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowse$AuthSubTypeID.json#"},"AuthTypeID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowse$AuthTypeID.json#"},"CCSStatusID":{"type":"integer"},"ComplianceDate":{"format":"date-time","nullable":true,"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"DecisionDate":{"format":"date-time","nullable":true,"type":"string"},"DecisionType":{"type":"string","maxLength":30},"DueDate":{"format":"date-time","nullable":true,"type":"string"},"ExpectedByDate":{"format":"date-time","nullable":true,"type":"string"},"IPID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowse$IPID.json#"},"IVID":{"type":"integer","primaryKey":"supplied"},"IsCCS":{"type":"boolean"},"IsInError":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"NextReviewDate":{"format":"date-time","nullable":true,"type":"string"},"OutboundQueuedTime":{"format":"date-time","nullable":true,"type":"string"},"PatientPlanID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowse$PatientPlanID.json#"},"ReceivedDate":{"format":"date-time","nullable":true,"type":"string"},"ScanDocID":{"type":"integer"},"UmUrgencyID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowse$UmUrgencyID.json#"},"XePatientVisit":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowse$XePatientVisit.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowse.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3, schema_4, schema_5]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
