/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.calllog.CallLogContact$NameTitleID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.calllog.CallLogContact$XePhone.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.calllog.CallLogContact.json#"},"CallContactType":{"type":"string","maxLength":100},"CallerID":{"type":"integer"},"FacilityID":{"type":"integer"},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"IPID":{"type":"integer"},"IVID":{"type":"integer"},"IsCallToContact":{"type":"boolean"},"IsCallToFacility":{"type":"boolean"},"IsCallToPatient":{"type":"boolean"},"Name":{"type":"string","maxLength":100},"NameTitleID":{"oneOf":[{"nullable":true,"type":"string","maxLength":16},{"$ref":"com.thrasys.xnet.erp.xmlobjects.calllog.CallLogContact$NameTitleID.json#"}]},"PatientContactID":{"type":"integer"},"PlacerID":{"type":"integer"},"RelationshipName":{"type":"string","maxLength":100},"XePhone":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.calllog.CallLogContact$XePhone.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.calllog.CallLogContact.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.calllog.CallLogContact.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
