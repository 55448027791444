/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.delta.XeVisitAssessment$FeatureGates.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.delta.XeVisitAssessment$InitialActions.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.delta.XeVisitAssessment$Services.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.delta.XeVisitAssessment.json#"},"AssessmentID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"RightsBase":{"type":"string","maxLength":32},"WellKnownID":{"type":"string","maxLength":50},"featureGates":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XeVisitAssessment$FeatureGates.json#"},"initialActions":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XeVisitAssessment$InitialActions.json#"},"services":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XeVisitAssessment$Services.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.delta.XeVisitAssessment.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.delta.XeVisitAssessment.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
