/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowseWithDupToAuthIVID$XeAuthVisit.json#"},"Active":{"type":"boolean"},"ActiveFilter":{"type":"string"},"AuthIVID":{"type":"integer"},"AuthIVIDFilter":{"type":"string"},"Comments":{"type":"string","maxLength":1024},"IVID":{"type":"integer"},"RelationType":{"type":"string"},"RelationTypeFilter":{"type":"string"},"RelationTypeOperator":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowseWithDupToAuthIVID$XeAuthVisit.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.auth.XeAuthBrowseWithDupToAuthIVID$XeAuthVisit.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
