/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse$XeVisitAssessment.json#"},"AssessmentID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EnterpriseID":{"type":"string","maxLength":30},"IPID":{"type":"integer"},"IVID":{"type":"integer"},"IsQuickSave":{"type":"string","maxLength":1},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"QuickSaveOwner":{"type":"integer"},"ReportID":{"type":"integer"},"RestrictValue":{"maximum":99999,"type":"integer"},"RightsBase":{"type":"string","maxLength":32},"Sequence":{"maximum":99,"type":"integer"},"Status":{"type":"string","maxLength":32},"Valid":{"type":"boolean"},"VisitAssessmentID":{"type":"integer","primaryKey":"generated"}},"$id":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse$XeVisitAssessment.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse$XeVisitAssessment.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
