/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.encform.AbstractXeEncForm$FacilityID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$IPID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.encform.AbstractXeEncForm$ValidateCodeID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$EncFormID.json#"},"Active":{"defaultValue":true,"type":"boolean"},"ActiveFilter":{"type":"string"},"Comments":{"type":"string","maxLength":1000},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EncFormID":{"type":"integer","primaryKey":"generated"},"EncounterDate":{"format":"date","nullable":true,"type":"string"},"EnterpriseID":{"type":"string","maxLength":30},"FacilityID":{"oneOf":[{"nullable":true,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.xmlobjects.encform.AbstractXeEncForm$FacilityID.json#"}]},"FacilityIDAsset":{"type":"string"},"IPID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$IPID.json#"},"IsDraft":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"OutboundQueuedTime":{"format":"date-time","nullable":true,"type":"string"},"OverrideValidation":{"type":"string","maxLength":1},"PayerClaimControlNumber":{"type":"string","maxLength":100},"PointOfServiceID":{"type":"integer"},"ReferringProviderID_EnterpriseID":{"type":"string"},"ReferringProviderName":{"type":"string","maxLength":100},"SBCodeID":{"maximum":9007199254740991,"type":"integer"},"SBDate":{"format":"date","nullable":true,"type":"string"},"Status":{"type":"string","maxLength":50},"ValidateCodeID":{"oneOf":[{"nullable":true,"type":"string","maxLength":100},{"$ref":"com.thrasys.xnet.erp.xmlobjects.encform.AbstractXeEncForm$ValidateCodeID.json#"}]},"ValidateSucceed":{"type":"boolean"}},"$id":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$EncFormID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$EncFormID.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
