/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.calllog.XeCallLogBrowseQuery.json#"},"CallLogID":{"type":"integer","primaryKey":"generated"},"CallType":{"type":"string","maxLength":30},"CallerID":{"type":"integer"},"CatalogID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"FollowUpActionID":{"type":"string","maxLength":16},"IPID":{"type":"integer"},"IVID":{"type":"integer"},"IsCallToSearch":{"type":"string","maxLength":1},"IsInError":{"type":"boolean"},"IsPatient":{"type":"string","maxLength":1},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"OutcomeID":{"type":"string","maxLength":60},"PatientContactID":{"type":"integer"},"PlacerID":{"type":"integer"},"ProgramID":{"type":"string","maxLength":16},"ServiceID":{"type":"integer"},"StaffID":{"type":"string"},"StartDateTimeMax":{"format":"date-time","nullable":true,"type":"string"},"StartDateTimeMin":{"format":"date-time","nullable":true,"type":"string"},"VisitID":{"type":"string","maxLength":30},"VisitTypeID":{"type":"string","maxLength":16},"WaitListSubCategoryID":{"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.calllog.XeCallLogBrowseQuery.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.calllog.XeCallLogBrowseQuery.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
