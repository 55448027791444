/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoDetail$XgCustomLanguage.json#"},"Active":{"type":"boolean"},"BookID":{"type":"string","maxLength":64},"CorrespondenceInfoID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"CustomLanguageCode":{"type":"string","maxLength":50},"CustomLanguageID":{"type":"integer","primaryKey":"generated"},"Description":{"type":"string","maxLength":500},"EffectiveDateTime":{"format":"local-date-time","nullable":true,"type":"string"},"EffectiveDateTimeOff":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"QuerySort":{"type":"string"},"SequencePosition":{"maximum":99999999,"type":"integer"},"SmartBookWKID":{"type":"string","maxLength":50}},"$id":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoDetail$XgCustomLanguage.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoDetail$XgCustomLanguage.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
