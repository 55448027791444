/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse$XePatientVisitProvider.json#"},"Action":{"type":"string","maxLength":10},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"Distance":{"exclusiveMaximum":1000,"type":"number"},"FilterBits":{"maximum":9999999999,"type":"integer"},"IPID":{"type":"integer"},"IVID":{"type":"integer"},"IsUniqueByProviderTypeID":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"ProviderTypeID":{"type":"string","maxLength":30},"RestrictValue":{"maximum":99999,"type":"integer"},"RightID":{"type":"string","maxLength":60},"ServiceID":{"type":"integer"},"StaffID":{"type":"integer"},"StaffID_EnterpriseID":{"type":"string"},"VisitProviderID":{"type":"integer","primaryKey":"generated"}},"$id":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse$XePatientVisitProvider.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse$XePatientVisitProvider.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
