/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardResult.json#"},"Display":{"type":"boolean"},"DisplayColumn":{"type":"string","maxLength":100},"DisplayDate":{"format":"date-time","nullable":true,"type":"string"},"DisplayDateLocal":{"format":"local-date-time","nullable":true,"type":"string"},"DisplayDateLocalOff":{"type":"string"},"DisplayName":{"type":"string","maxLength":300},"DisplayNumeric":{"exclusiveMaximum":9223372036854776000,"type":"number"},"DisplayResult":{"type":"string","maxLength":100},"DisplaySort":{"type":"string","maxLength":10},"DisplayStyle":{"type":"string"},"DisplayStyleTypeSite":{"type":"string"},"DisplayType":{"type":"string","maxLength":10},"EnterpriseID":{"type":"string"},"ResolvedError":{"type":"string","maxLength":200},"ResolvedValue":{"type":"string","maxLength":100},"RestrictValue":{"maximum":99999,"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardResult.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardResult.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
