/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.assessment.AssessmentGroupResponse$GroupAssessmentID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.assessment.AssessmentGroupResponse.json#"},"Active":{"type":"boolean"},"AssessmentID":{"type":"integer","primaryKey":"supplied"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"GroupAssessmentID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.assessment.AssessmentGroupResponse$GroupAssessmentID.json#"},"IPID":{"type":"string"},"IVID":{"type":"string"},"InternalCode":{"type":"string"},"IsIgnoreValidations":{"type":"string"},"IsRemoveable":{"type":"boolean"},"IsScanDoc":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"RuleInfoID":{"maximum":9007199254740991,"type":"integer"},"RuleSetID":{"type":"integer"},"Sequence":{"exclusiveMaximum":10000000000,"type":"number"}},"$id":"com.thrasys.xnet.erp.xmlobjects.assessment.AssessmentGroupResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.assessment.AssessmentGroupResponse.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
