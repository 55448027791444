/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.assessment.XeAssessmentBase.json#"},"Active":{"type":"boolean"},"AssessmentID":{"type":"integer","primaryKey":"supplied"},"AssessmentSubTypeID":{"type":"string","maxLength":32},"AssessmentTypeID":{"type":"string","maxLength":32},"AssociationType":{"type":"string","maxLength":20},"AuthLevel":{"maximum":9,"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EnterpriseID":{"type":"string"},"InternalCode":{"type":"string","maxLength":50},"IsScanDoc":{"type":"boolean"},"IsSystemGenerated":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":100},"ProgramBits":{"maximum":9007199254740991,"type":"integer"},"RightsBase":{"type":"string","maxLength":32},"WellKnownID":{"type":"string","maxLength":50}},"$id":"com.thrasys.xnet.erp.xmlobjects.assessment.XeAssessmentBase.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.assessment.XeAssessmentBase.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
