/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse$XeSmartBook.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse$XeCustomLanguage.json#"},"Active":{"type":"boolean"},"CorrespondenceInfoID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"CustomLanguageCode":{"type":"string","maxLength":50},"CustomLanguageID":{"type":"integer","primaryKey":"supplied"},"Description":{"type":"string","maxLength":500},"EffectiveDateTime":{"format":"local-date-time","nullable":true,"type":"string"},"EffectiveDateTimeOff":{"type":"string"},"EnterpriseID":{"type":"string"},"IsTranslationReq":{"type":"boolean"},"LanguageID":{"type":"string","maxLength":16},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"SequencePosition":{"maximum":99999999,"type":"integer"},"XeSmartBook":{"$ref":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse$XeSmartBook.json#"},"XeSmartBookInstance":{"$ref":"com.thrasys.xnet.erp.xmlobjects.uitemplate.XeSmartBookInstance.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse$XeCustomLanguage.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse$XeCustomLanguage.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
