/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Conditions.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Target.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Advanced.json#"},"conditions":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Conditions.json#"},"type":"array"},"filterType":{"maximum":9,"type":"integer"},"logicalOperator":{"type":"string"},"schema":{"type":"string"},"target":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Target.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Advanced.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Advanced.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
