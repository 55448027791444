/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.assessmentcatalog.XeAssessmentCatalog$AssessmentID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.assessmentcatalog.XeAssessmentCatalog$XeAssessmentCatalogDerived.json#"},"Active":{"type":"boolean"},"AssessmentID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.assessmentcatalog.XeAssessmentCatalog$AssessmentID.json#"},"CatalogID":{"type":"integer","primaryKey":"supplied"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"IsCommon":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"QuerySort":{"type":"string"},"RightID":{"type":"string","maxLength":64},"Sequence":{"exclusiveMaximum":10000000000,"type":"number"}},"$id":"com.thrasys.xnet.erp.xmlobjects.assessmentcatalog.XeAssessmentCatalog$XeAssessmentCatalogDerived.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.assessmentcatalog.XeAssessmentCatalog$XeAssessmentCatalogDerived.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
