/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoDetail$XeReportLocale.json#"},"Active":{"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"FileID":{"type":"integer"},"FileName":{"type":"string","maxLength":200},"Locale":{"type":"string","maxLength":10},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"ReportID":{"type":"integer"},"ReportLocaleID":{"type":"integer","primaryKey":"generated"},"TemplateType":{"type":"string","maxLength":10}},"$id":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoDetail$XeReportLocale.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoDetail$XeReportLocale.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
