/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse$ActionList.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardResult.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse.json#"},"ActionList":{"$ref":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse$ActionList.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DashboardID":{"type":"string","primaryKey":"supplied","maxLength":50},"DoB":{"format":"local-date-time","nullable":true,"type":"string"},"DoBOff":{"type":"string"},"FamilyName":{"type":"string","maxLength":100},"GivenName":{"type":"string","maxLength":100},"IPID":{"type":"integer","primaryKey":"supplied"},"IVID":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"NameTitleID":{"type":"string","maxLength":16},"NationalID":{"type":"string","maxLength":30},"PatientID":{"type":"string","maxLength":50},"RestrictValue":{"maximum":99999,"type":"integer"},"Result":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardResult.json#"},"type":"array"},"SecondName":{"type":"string","maxLength":100},"SexID":{"type":"string","maxLength":16},"Suffix":{"type":"string","maxLength":10}},"$id":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
