/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$WaitListSubCategoryID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$XeWaitList.json#"},"CategoryID":{"type":"string","maxLength":16},"CategoryIDFilter":{"type":"string"},"ClosedComment":{"type":"string","maxLength":500},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Description":{"type":"string","maxLength":1024},"IsInError":{"type":"boolean"},"IsInErrorFilter":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"PreferredDateTime":{"format":"date-time","nullable":true,"type":"string"},"ProviderID":{"type":"integer"},"ProviderIDCacheKey":{"type":"string"},"StatusID":{"type":"string","maxLength":16},"StatusIDOperator":{"type":"string"},"WaitListID":{"type":"integer","primaryKey":"generated"},"WaitListSubCategoryID":{"oneOf":[{"nullable":true,"maximum":9007199254740991,"type":"integer"},{"$ref":"com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$WaitListSubCategoryID.json#"}]}},"$id":"com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$XeWaitList.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.calllog.FactXeCallLog$XeWaitList.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
