/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.auth.XeAuthDueDates.json#"},"CanExtend":{"type":"boolean"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"DecisionDueDate":{"format":"date-time","nullable":true,"type":"string"},"Description":{"type":"string","maxLength":500},"ExpectedByDate":{"format":"date-time","nullable":true,"type":"string"},"ExtensionTime":{"defaultValue":-1,"exclusiveMaximum":1000000000000,"type":"number"},"ExtensionUnit":{"defaultValue":"CH","type":"string","maxLength":2},"HealthPlanTypeID":{"type":"string"},"IsExtended":{"type":"boolean"},"IsOverridable":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"NotifyMemberDueDate":{"format":"date-time","nullable":true,"type":"string"},"NotifyProviderDueDate":{"format":"date-time","nullable":true,"type":"string"},"ReceivedDate":{"format":"date-time","nullable":true,"type":"string"},"UmUrgencyID":{"type":"string","maxLength":32},"VerbalMemberDueDate":{"format":"date-time","nullable":true,"type":"string"},"VerbalProviderDueDate":{"format":"date-time","nullable":true,"type":"string"}},"required":["ExtensionTime","ExtensionUnit"],"$id":"com.thrasys.xnet.erp.xmlobjects.auth.XeAuthDueDates.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.auth.XeAuthDueDates.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
