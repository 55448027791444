/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse$BenefitLimit.json#"},"BenefitDescription":{"type":"string"},"BenefitID":{"type":"string"},"CoInsurance":{"type":"string"},"CopayAmount":{"exclusiveMaximum":1000000,"type":"number"},"Description":{"type":"string"},"IsRentalOnly":{"type":"boolean"},"PurchaseUnitPrice":{"exclusiveMaximum":1000000,"type":"number"},"QtyAllowed":{"maximum":999999,"type":"integer"},"QtyConsumed":{"maximum":999999,"type":"integer"},"QtyLimit":{"maximum":999999,"type":"integer"},"RentalAllowed":{"maximum":999999,"type":"integer"},"RentalConsumed":{"maximum":999999,"type":"integer"},"RentalLimit":{"exclusiveMaximum":1000000,"type":"number"},"RentalQtyLimit":{"maximum":999999,"type":"integer"},"RentalUnitPrice":{"exclusiveMaximum":1000000,"type":"number"},"Status":{"type":"string"},"UoM":{"type":"string"},"VisitTerm":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse$BenefitLimit.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse$BenefitLimit.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
