/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchEntry$XeClaimBatchEntry.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchEntry.json#"},"Active":{"type":"boolean"},"ClaimBatchID":{"type":"string","primaryKey":"generated","maxLength":120},"ClearingHouseID":{"type":"string","maxLength":200},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"ErrorDescription":{"type":"string","maxLength":1024},"IsInError":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"StatusID":{"type":"string","maxLength":32},"XeClaimBatchEntry":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchEntry$XeClaimBatchEntry.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchEntry.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchEntry.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
