/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse$AppliedCoveragePlan.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse$CoveragePlan.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse$XeOrderCodes.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse$XeOrderBase.json#"},"AllowedUnits":{"maximum":99999,"type":"integer"},"AppliedCoveragePlan":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse$AppliedCoveragePlan.json#"},"AuthorizedUnits":{"maximum":99999,"type":"integer"},"Code":{"type":"string","maxLength":32},"CodeTypeID":{"type":"string","maxLength":30},"ConsumedUnits":{"maximum":99999,"type":"integer"},"CoveragePlan":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse$CoveragePlan.json#"},"type":"array"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"IsBenefitsReconciliation":{"type":"boolean"},"IsCovered":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"RequestedUnits":{"maximum":99999,"type":"integer"},"ServiceSubTypeID":{"type":"string","maxLength":32},"ServiceTypeID":{"type":"string","maxLength":32},"XeOrderCodes":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse$XeOrderCodes.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse$XeOrderBase.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse$XeOrderBase.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
