/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Target.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$RelativeDate.json#"},"filterType":{"maximum":9,"type":"integer"},"includeToday":{"type":"boolean"},"operator":{"type":"string"},"schema":{"type":"string"},"target":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$Target.json#"},"timeUnitCount":{"type":"integer"},"timeUnitType":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$RelativeDate.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.delta.XePowerBI$RelativeDate.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
