/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse$XePatientList.json#"},"Active":{"type":"boolean"},"Comments":{"type":"string","maxLength":200},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"IPID":{"type":"integer","primaryKey":"supplied"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"PatientListID":{"type":"integer","primaryKey":"supplied"},"RestrictValue":{"maximum":99999,"type":"integer"},"Sequence":{"maximum":99,"type":"integer"},"StatusID":{"type":"integer"},"StatusList":{"type":"string"},"StatusName":{"type":"string"},"Valid":{"type":"boolean"}},"$id":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse$XePatientList.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse$XePatientList.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
