/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.delta.NotificationThread$XeNotificationThread.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.delta.NotificationThread$FeatureGates.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.delta.NotificationThread$InitialActions.json';
import schema_3 from './com.thrasys.xnet.erp.xmlobjects.delta.NotificationThread$Services.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.delta.NotificationThread.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"EnterpriseID":{"type":"string","maxLength":30},"IsUseIPIDContext":{"type":"boolean"},"IsUseSelectedIVIDContext":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":100},"ThreadID":{"type":"integer","primaryKey":"generated"},"WellKnownID":{"type":"string","maxLength":50},"XeNotificationThread":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.NotificationThread$XeNotificationThread.json#"},"type":"array"},"featureGates":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.NotificationThread$FeatureGates.json#"},"initialActions":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.NotificationThread$InitialActions.json#"},"services":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.NotificationThread$Services.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.delta.NotificationThread.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.delta.NotificationThread.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
