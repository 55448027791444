/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.clinicalrecordexport.XeClinicalRecordExport$ResourceID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.clinicalrecordexport.XeClinicalRecordExport.json#"},"ActionType":{"type":"string","maxLength":60},"Comments":{"type":"string","maxLength":256},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string"},"ExportDateTime":{"format":"date-time","nullable":true,"type":"string"},"ExportID":{"type":"integer","primaryKey":"generated"},"IPID":{"type":"integer"},"IVID":{"type":"integer"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Purpose":{"type":"string","maxLength":256},"QuerySort":{"type":"string"},"RecordType":{"type":"string","maxLength":10},"RecordVisit":{"type":"string","maxLength":1},"ResourceID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.clinicalrecordexport.XeClinicalRecordExport$ResourceID.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.clinicalrecordexport.XeClinicalRecordExport.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.clinicalrecordexport.XeClinicalRecordExport.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
