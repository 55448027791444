/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.assessmentcatalog.XeAssessmentCatalog$AssessmentSubTypeID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.assessmentcatalog.XeAssessmentCatalog$AssessmentTypeID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.assessmentcatalog.XeAssessmentCatalog$AssessmentID.json#"},"Active":{"type":"boolean"},"AssessmentID":{"type":"integer","primaryKey":"supplied"},"AssessmentSubTypeID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.assessmentcatalog.XeAssessmentCatalog$AssessmentSubTypeID.json#"},"AssessmentSubTypeIDQuerySort":{"type":"string"},"AssessmentTypeID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.assessmentcatalog.XeAssessmentCatalog$AssessmentTypeID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"EnteredDate":{"format":"date-time","nullable":true,"type":"string"},"InternalCode":{"type":"string","maxLength":50},"IsChecked":{"defaultValue":"n","type":"string"},"IsSystemGenerated":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"Name":{"type":"string","maxLength":100},"ProgramBits":{"maximum":9007199254740991,"type":"integer"},"RightsBase":{"type":"string","maxLength":32},"Sequence":{"type":"string"},"Status":{"type":"string","maxLength":32},"WellKnownID":{"type":"string","maxLength":50}},"$id":"com.thrasys.xnet.erp.xmlobjects.assessmentcatalog.XeAssessmentCatalog$AssessmentID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.assessmentcatalog.XeAssessmentCatalog$AssessmentID.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
