/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.delta.svcs$Headers.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.delta.svcs$Service.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.delta.XeDashboard$Services.json#"},"headers":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.svcs$Headers.json#"},"service":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.svcs$Service.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.delta.XeDashboard$Services.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.delta.XeDashboard$Services.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
