/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.assessment.AssessmentGroupResponse$GroupAssessmentID.json#"},"Active":{"type":"boolean"},"AssessmentID":{"type":"integer","primaryKey":"supplied"},"AssessmentSubTypeID":{"type":"string","maxLength":32},"AssessmentTypeID":{"type":"string","maxLength":32},"AuthLevel":{"maximum":9,"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"GroupAssessmentID":{"type":"string"},"InternalCode":{"type":"string","maxLength":50},"IsRemoveable":{"type":"string"},"IsScanDoc":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"Name":{"type":"string","maxLength":100},"RightsBase":{"type":"string","maxLength":32},"WellKnownID":{"type":"string","maxLength":50}},"$id":"com.thrasys.xnet.erp.xmlobjects.assessment.AssessmentGroupResponse$GroupAssessmentID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.assessment.AssessmentGroupResponse$GroupAssessmentID.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
