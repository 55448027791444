/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.UISet.XeUISet$XeUITemplateSet.json#"},"Active":{"type":"boolean"},"BookID":{"type":"string","maxLength":64},"ClassName":{"type":"string","maxLength":200},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DashboardID":{"type":"string","maxLength":50},"ID":{"type":"integer","primaryKey":"generated"},"IsDisplay":{"defaultValue":true,"type":"boolean"},"IsOpen":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":100},"QuerySort":{"type":"string"},"RuleInfoID":{"type":"string","maxLength":32},"RuleSetID":{"type":"integer"},"Sequence":{"maximum":99,"type":"integer"},"TemplateAction":{"type":"string","maxLength":10},"TemplateQuery":{"type":"string","maxLength":2147483647},"TemplateTrigger":{"type":"string","maxLength":100},"UISetID":{"type":"integer"},"UITemplate":{"type":"string","maxLength":100}},"$id":"com.thrasys.xnet.erp.xmlobjects.UISet.XeUISet$XeUITemplateSet.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.UISet.XeUISet$XeUITemplateSet.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
