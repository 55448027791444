/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse$XeWork.json#"},"DisplayColumn":{"type":"string","maxLength":100},"DisplaySort":{"type":"string","maxLength":10},"Overwrite":{"defaultValue":false,"type":"boolean"},"ProcessingTypeID":{"type":"string"},"RecordID":{"type":"integer","primaryKey":"generated"},"Sequence":{"maximum":99,"type":"integer"},"StateOwner":{"type":"integer"},"StateOwnerExpire":{"format":"date-time","nullable":true,"type":"string"},"StateOwnerName":{"type":"string","maxLength":60},"TokenID":{"type":"string"},"WorkState":{"type":"string"},"WorkflowID":{"type":"integer"}},"$id":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse$XeWork.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse$XeWork.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
