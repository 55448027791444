/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitRequest$IPID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitRequest$PatientPlanID.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitRequest$XeOrderBase.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitRequest.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"ExpectedByDate":{"format":"date-time","nullable":true,"type":"string"},"IPID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitRequest$IPID.json#"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"PatientPlanID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitRequest$PatientPlanID.json#"},"XeOrderBase":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitRequest$XeOrderBase.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitRequest.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitRequest.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
