/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.delta.svcs$Headers.json#"},"xe_background":{"type":"string"},"xe_enterprise_id":{"type":"string"},"xe_enterprise_scope":{"type":"string"},"xe_fetchSize":{"type":"string"},"xe_pagesize":{"type":"string"},"xe_querysort":{"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.delta.svcs$Headers.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.delta.svcs$Headers.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
