/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.delta.XeDashboard$FeatureGates.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.delta.XeDashboard$InitialActions.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.delta.XeDashboard$Services.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.delta.XeDashboard.json#"},"BookID":{"type":"string","maxLength":64},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DashboardID":{"type":"string","primaryKey":"supplied","maxLength":50},"GroupID":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":200},"featureGates":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XeDashboard$FeatureGates.json#"},"initialActions":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XeDashboard$InitialActions.json#"},"services":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XeDashboard$Services.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.delta.XeDashboard.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.delta.XeDashboard.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
