/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse$XeReportLocale.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse$ReportID.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"Description":{"type":"string","maxLength":500},"FileID":{"type":"integer"},"FileName":{"type":"string","maxLength":200},"FilterValue":{"type":"string","maxLength":60},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"Name":{"type":"string","maxLength":100},"ReportID":{"type":"integer","primaryKey":"generated"},"TemplateType":{"type":"string","maxLength":10},"XeReportLocale":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse$XeReportLocale.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse$ReportID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse$ReportID.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
