/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoResponse.json#"},"Active":{"type":"boolean"},"AllowAttachment":{"type":"boolean"},"AllowCoverSelect":{"type":"boolean"},"CorrespondenceCode":{"type":"string","maxLength":50},"CorrespondenceInfoID":{"type":"integer","primaryKey":"generated"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"CustomLanguageID":{"type":"integer"},"DefaultFaxCoverWKID":{"type":"string","maxLength":50},"DefaultMailCoverWKID":{"type":"string","maxLength":50},"Description":{"type":"string","maxLength":500},"EffectiveDateTime":{"format":"local-date-time","nullable":true,"type":"string"},"EffectiveDateTimeOff":{"type":"string"},"FileID":{"type":"string"},"IPID":{"type":"string"},"IVID":{"type":"string"},"IsLookupIncludeText":{"type":"boolean"},"IsQuickLetter":{"type":"boolean"},"IsSelectable":{"type":"boolean"},"IsWorkList":{"type":"boolean"},"LetterType":{"type":"string","maxLength":60},"LineOfBusiness":{"type":"string","maxLength":60},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string","maxLength":100},"OldFileID":{"type":"string"},"ProgramBits":{"maximum":9007199254740991,"type":"integer"},"ReportCode":{"type":"string","maxLength":50},"RightID":{"type":"string","maxLength":60},"SmartBookWellKnownID":{"type":"string"},"SubType":{"type":"string","maxLength":60},"TemplateType":{"type":"string","maxLength":10},"ValidationTest":{"type":"string","maxLength":2147483647},"VisitStatus":{"type":"string","maxLength":16},"WellKnownID":{"type":"string","maxLength":50}},"$id":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoResponse.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
