/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.clinicallinks.XeClinicalLinks.json#"},"Active":{"type":"boolean"},"CatalogAssetName":{"type":"string"},"CatalogIDName":{"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"FilterValue":{"type":"string","maxLength":64},"IsCrossDomainAccess":{"type":"boolean"},"LinkID":{"type":"integer","primaryKey":"generated"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"Name":{"type":"string","maxLength":100},"NodeAssetName":{"type":"string"},"ParentID":{"type":"integer"},"ParentIDName":{"type":"string"},"URL":{"type":"string","maxLength":2000},"UsageType":{"type":"string","maxLength":10},"XeClinicalLinks":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.clinicallinks.XeClinicalLinks.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.clinicallinks.XeClinicalLinks.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.clinicallinks.XeClinicalLinks.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
