/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails$ClaimID.json#"},"ClaimID":{"type":"integer","primaryKey":"generated"},"ClaimNumber":{"type":"string","maxLength":60},"ClaimStatus":{"type":"string","maxLength":30},"ClaimType":{"type":"string","maxLength":10},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"EnterpriseID":{"type":"string"},"IPID":{"type":"integer"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"RestrictValue":{"maximum":99999,"type":"integer"},"StartDate":{"format":"date","nullable":true,"type":"string"}},"$id":"com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails$ClaimID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthDetails$ClaimID.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
