/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse$IPID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse$XeOrderBase.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse.json#"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"ExpectedByDate":{"format":"date-time","nullable":true,"type":"string"},"IPID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse$IPID.json#"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"PatientPlanID":{"type":"string","maxLength":32},"XeOrderBase":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse$XeOrderBase.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.auth.AuthCheckBenefitResponse.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
