/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoDetail$AssessmentID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoDetail$ReportID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoDetail$XgCorrespInfoAttach.json#"},"Active":{"type":"boolean"},"AssessmentID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoDetail$AssessmentID.json#"},"CorrespInfoAttachmentID":{"type":"integer","primaryKey":"generated"},"CorrespondenceInfoID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"IsRequired":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"QuerySort":{"type":"string"},"ReportID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoDetail$ReportID.json#"},"SequenceNo":{"maximum":9999,"type":"integer"},"UsageFlag":{"maximum":9999,"type":"integer"},"WellKnownID":{"type":"string","maxLength":50}},"$id":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoDetail$XgCorrespInfoAttach.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.XgCorrespondenceInfoDetail$XgCorrespInfoAttach.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
