/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoDetail$XeCorrespInfoRecip.json#"},"Active":{"type":"boolean"},"CorrespInfoRecipID":{"type":"integer","primaryKey":"supplied"},"CorrespondenceInfoID":{"type":"integer"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"ProviderTypeID":{"type":"string","maxLength":50},"ReportConfig1":{"type":"string","maxLength":60},"ReportConfig2":{"type":"string","maxLength":60},"ReportConfig3":{"type":"string","maxLength":60},"SendMethod":{"type":"string","maxLength":60}},"$id":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoDetail$XeCorrespInfoRecip.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoDetail$XeCorrespInfoRecip.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
