/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse$XeCallLog.json#"},"CallLogID":{"type":"integer","primaryKey":"generated"},"CallType":{"type":"string","maxLength":30},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"IPID":{"type":"integer"},"IVID":{"type":"integer"},"MakeCall":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"RestrictValue":{"maximum":99999,"type":"integer"},"Sequence":{"maximum":99,"type":"integer"},"Valid":{"type":"boolean"}},"$id":"com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse$XeCallLog.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.dashboard.DashboardStructuredResponse$XeCallLog.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
