/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$EncFormID.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$StatusID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$XeClaimBatchEntry.json#"},"Active":{"type":"boolean"},"ClaimBatchEntryID":{"type":"string","primaryKey":"generated","maxLength":120},"ClaimBatchID":{"type":"string","maxLength":120},"ClearingHouseID":{"type":"string","maxLength":200},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"EncFormID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$EncFormID.json#"},"IsInError":{"type":"boolean"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"StatusID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$StatusID.json#"},"SubBatchID":{"type":"string","maxLength":120},"TransactionType":{"type":"string","maxLength":30}},"$id":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$XeClaimBatchEntry.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$XeClaimBatchEntry.json';

export const toReferencedSchemas = () => ([schema_0, schema_1]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
