/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoDetail$SmartBook.json#"},"Active":{"type":"boolean"},"AttrType":{"type":"string","maxLength":24},"BookID":{"type":"string","maxLength":64},"Code":{"type":"string","maxLength":30},"IsRequired":{"type":"boolean"},"IsSet":{"type":"boolean"},"IsValid":{"type":"boolean"},"Modified":{"type":"boolean"},"Name":{"type":"string","maxLength":100},"Sequence":{"exclusiveMaximum":100000,"type":"number"},"WellKnownID":{"type":"string","maxLength":50}},"$id":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoDetail$SmartBook.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoDetail$SmartBook.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
