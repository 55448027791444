/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$HealthPlanPayerID.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$ClearingHouseID.json#"},"Active":{"type":"boolean"},"ClearingHouseID":{"type":"string","primaryKey":"supplied","maxLength":200},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":60},"HealthPlanPayerID":{"$ref":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$HealthPlanPayerID.json#"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":60},"Name":{"type":"string","maxLength":2147483647}},"$id":"com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$ClearingHouseID.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.claimbatch.ClaimBatchFull$ClearingHouseID.json';

export const toReferencedSchemas = () => ([schema_0]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
