/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';
import schema_0 from './com.thrasys.xnet.erp.xmlobjects.delta.XeWork$XeWorkFilter.json';
import schema_1 from './com.thrasys.xnet.erp.xmlobjects.delta.XeWork$FeatureGates.json';
import schema_2 from './com.thrasys.xnet.erp.xmlobjects.delta.XeWork$InitialActions.json';
import schema_3 from './com.thrasys.xnet.erp.xmlobjects.delta.XeWork$Services.json';

export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.delta.XeWork.json#"},"IgnoreStateOwnerExpire":{"type":"boolean"},"IgnoreStateOwnerExpireDefault":{"type":"string"},"ResourceID":{"type":"string"},"StateOwner":{"type":"integer"},"WorkState":{"type":"string"},"WorkTypeID":{"items":{"type":"string"},"type":"array"},"XeWorkFilter":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XeWork$XeWorkFilter.json#"},"type":"array"},"featureGates":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XeWork$FeatureGates.json#"},"initialActions":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XeWork$InitialActions.json#"},"services":{"$ref":"com.thrasys.xnet.erp.xmlobjects.delta.XeWork$Services.json#"}},"$id":"com.thrasys.xnet.erp.xmlobjects.delta.XeWork.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.delta.XeWork.json';

export const toReferencedSchemas = () => ([schema_0, schema_1, schema_2, schema_3]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
