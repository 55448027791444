/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoDetail$XeSmartBookInstance.json#"},"Active":{"type":"boolean"},"AttrType":{"type":"string"},"BookFileID":{"type":"string"},"BookID":{"type":"string","maxLength":64},"Code":{"type":"string"},"CompletedDate":{"format":"date-time","nullable":true,"type":"string"},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"DerivedRule":{"type":"string"},"EndDate":{"format":"date-time","nullable":true,"type":"string"},"InstanceID":{"type":"integer","primaryKey":"generated"},"IsRequired":{"type":"boolean"},"IsSet":{"type":"boolean"},"IsValid":{"type":"boolean"},"Modified":{"type":"string"},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"Name":{"type":"string"},"ParentID":{"type":"integer"},"ResultDate":{"format":"date-time","nullable":true,"type":"string"},"ResultValue":{"type":"string","maxLength":4000},"Sequence":{"exclusiveMaximum":100000,"type":"number"},"StartDate":{"format":"date-time","nullable":true,"type":"string"},"WellKnownID":{"type":"string","maxLength":50},"XeSmartBookInstance":{"items":{"$ref":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoDetail$XeSmartBookInstance.json#"},"type":"array"}},"$id":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoDetail$XeSmartBookInstance.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoDetail$XeSmartBookInstance.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
