/* json-schema-loader */
import { toRegisterSchema } from '../src/registration';


export const baseSchema = JSON.parse(`{"type":"object","properties":{"$schema":{"const":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse$XeReportLocale.json#"},"Active":{"type":"boolean"},"AssetName":{"type":"string","maxLength":50},"CreateTStamp":{"format":"date-time","nullable":true,"type":"string"},"CreationUser":{"type":"string","maxLength":30},"Fields":{"type":"string","maxLength":400},"FileID":{"type":"integer"},"FileName":{"type":"string","maxLength":200},"GroupID":{"type":"integer"},"LanguageID":{"type":"string"},"Locale":{"type":"string","maxLength":10},"ModifiedTStamp":{"format":"date-time","nullable":true,"type":"string"},"ModifiedUser":{"type":"string","maxLength":30},"OutputType":{"type":"string","maxLength":10},"Parameters":{"type":"string","maxLength":200},"Query":{"type":"string","maxLength":500},"ReportCode":{"type":"string","maxLength":128},"ReportID":{"type":"integer"},"ReportLocaleID":{"type":"integer","primaryKey":"generated"},"ReportTypeID":{"type":"integer"},"TemplateType":{"type":"string","maxLength":10},"Url":{"type":"string","maxLength":200}},"$id":"com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse$XeReportLocale.json#","$schema":"http://json-schema.org/draft-07/schema#","additionalProperties":false}`);

export const name = 'com.thrasys.xnet.erp.xmlobjects.correspondenceinfo.CorrespondenceInfoResponse$XeReportLocale.json';

export const toReferencedSchemas = () => ([]);

export const registerSchema = toRegisterSchema(name, baseSchema, toReferencedSchemas);

export default {
  name,
  toReferencedSchemas,
  registerSchema,
  baseSchema
};
